import {
  FC,
  InputHTMLAttributes,
  ReactElement,
  TextareaHTMLAttributes,
  useCallback,
  useRef,
} from 'react'
import { Button } from '../../Button'
import { ControlWrapper } from '../ControlWrapper'
import s from './Input.module.css'

const Input: FC<InputProps> = (props) => {
  const {
    prefix,
    suffixIcon,
    onClickSuffix,
    required = true,
    ...passingProps
  } = props

  const root = useRef<HTMLInputElement>(null)

  const handleSuffixClick = useCallback(() => {
    if (typeof onClickSuffix !== 'function') {
      return
    }

    onClickSuffix?.()
    root.current?.focus()
  }, [onClickSuffix, root])

  return (
    <ControlWrapper>
      {prefix}
      <input
        required={required}
        className={s.control}
        {...passingProps}
        ref={root}
      />
      {suffixIcon && (
        <div className={s.suffix}>
          <Button
            {...(onClickSuffix === 'submit' && {
              type: 'submit',
              form: passingProps.form,
            })}
            variant="text"
            tabIndex={-1}
            onClick={handleSuffixClick}
          >
            {suffixIcon}
          </Button>
        </div>
      )}
    </ControlWrapper>
  )
}

type InputProps = Pick<
  PropsPool,
  | 'type'
  | 'form'
  | 'name'
  | 'defaultValue'
  | 'required'
  | 'minLength'
  | 'maxLength'
  | 'pattern'
  | 'placeholder'
  | 'autoFocus'
  | 'autoComplete'
> & {
  prefix?: ReactElement
  suffixIcon?: ReactElement
  onClickSuffix?: 'submit' | VoidFunction
}

type PropsPool = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export { Input }
export type { InputProps }
