import { FC, PropsWithChildren, ReactNode } from 'react'
import { createBooleanDataset } from '../../helpers'
import { Logo } from '../Logo'
import s from './Notice.module.css'

const Notice: FC<NoticeProps> = (props) => {
  const { title, footer, shadow = false, children } = props

  return (
    <section {...createBooleanDataset({ shadow })} className={s.root}>
      <div className={s.logo}>
        <Logo width="10rem" />
      </div>
      <h1 className={s.title}>{title}</h1>
      {children}
      <footer className={s.footer}>{footer}</footer>
    </section>
  )
}

type NoticeProps = PropsWithChildren & {
  title?: string
  footer?: ReactNode
  shadow?: boolean
}

export { Notice }
export type { NoticeProps }
