import { ContextType, createContext } from 'react'
import { getValues, Values } from './Form'

const validate = (form: HTMLFormElement, validation?: Validation) => {
  if (!validation) {
    return
  }

  return Object.entries(validation).reduce((pool, [name, rules]) => {
    const control = form.elements.namedItem(name)

    if (!isControl(control)) {
      console.warn(`Cannot find a form field named "${name}".`)
      return pool
    }

    for (const rule of rules) {
      const { test, message } = rule

      if (test === '') {
        if (!isNativeValid(control)) {
          return { ...pool, [name]: message }
        }

        continue
      }

      if (test instanceof RegExp) {
        if (!test.test(control.value)) {
          return { ...pool, [name]: message }
        }

        continue
      }

      if (typeof test === 'function') {
        const valid = test(control.value, getValues(form))

        if (!valid) {
          return { ...pool, [name]: message }
        }

        continue
      }

      if (control.validity[test]) {
        return { ...pool, [name]: message }
      }
    }

    return pool
  }, undefined as Errors)
}

const isControl = (element: unknown): element is Control =>
  (element instanceof HTMLInputElement ||
    element instanceof HTMLSelectElement) &&
  !!element.name

const isNativeValid = (control: Control) => {
  const names: (keyof ValidityState)[] = [
    'badInput',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'typeMismatch',
    'valueMissing',
  ]
  return names.every((name) => !control.validity[name])
}

const ErrorsContext = createContext<Record<string, string> | undefined>(
  undefined
)

type Validation = Record<
  string,
  {
    test:
      | keyof Omit<ValidityState, 'valid'>
      | ''
      | RegExp
      | ((value: string, values: Values) => boolean)
    message: string
  }[]
>

type Control = HTMLInputElement | HTMLSelectElement

type Errors = ContextType<typeof ErrorsContext>

export { validate, ErrorsContext }
export type { Validation, Errors, Control }
