import { Countries } from '@xoda/common'
import moment from 'moment-timezone'
import { FC, useCallback, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'
import { Api } from '../../api'
import { Button } from '../../components/Button'
import { Loading } from '../../components/Loading'
import { Logo } from '../../components/Logo'
import { Modal } from '../../components/Modal'
import { Notice } from '../../components/Notice'
import { Page } from '../../components/Page'
import {
  Checkbox,
  Field,
  Form,
  FormProps,
  FormRow,
  Input,
  PasswordInput,
  Select,
  Values,
} from '../../components/form'
import { formatMobile } from '../../helpers'
import { useSwitch } from '../../hooks/switch'
import { ReactComponent as Magnifier1 } from '../../icons/magnifier-1.svg'
import { Abn, BnItem, Nzbn } from '../../third-party/bn'
import s from './Home.module.css'

const Home: FC = () => {
  const {
    on: bnResultOpened,
    open: openBnResult,
    close: closeBnResult,
  } = useSwitch()
  const {
    on: timezoneDisabled,
    open: disableTimezone,
    close: enableTimezone,
  } = useSwitch()

  const { data: referrer } = useSWR<Dto.Referrer>(
    referrerCode && ['onboarding/referrer', { code: referrerCode }]
  )

  const { on: pending, open: startPending, close: stopPending } = useSwitch()

  const [step, setStep] = useState(0)
  const [countryCode, setCountryCode] = useState(Countries.raw[0].code)
  const [bnKeyword, setBnKeyword] = useState('')
  const [bnResult, setBnResult] = useState<BnItem[]>()
  const [selectedBn, setSelectedBn] = useState<BnItem>()
  const [searchFailed, setSearchFailed] = useState(false)
  const [areaCode, setAreaCode] = useState<string>()

  const allValues = useRef<Values>()
  const signUpDto = useRef<Dto.SignUp>()

  const createHandleClickBn = (bn: BnItem) => () => {
    setSelectedBn(bn)
    closeBnResult()
  }

  const country = Countries.findByCode(countryCode)!

  const stepOn = useCallback(() => {
    setStep((prev) => prev + 1)
  }, [])

  const stepOff = useCallback(() => {
    setStep((prev) => prev - 1)
  }, [])

  const handleBnSearchSubmit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      const keyword = values['bn-keyword'] as string

      if (!keyword) {
        toast.error(
          `Please add your ${
            country.bn!.name
          } number or business name before you continue`,
          { id: 'bn' }
        )
        return
      }

      setBnResult(undefined)
      openBnResult()

      try {
        const result = await (country.code === 'AU' ? Abn : Nzbn).search(
          keyword
        )
        setBnResult(result)
      } catch (error) {
        if (error instanceof Error) {
          toast.error(
            `Searching failed: ${error.message}\nPlease input manually.`
          )
        }

        closeBnResult()
        setSearchFailed(true)
      }

      setBnKeyword(keyword)
    },
    [openBnResult, country, closeBnResult]
  )

  const handleReSearchClick = useCallback(() => {
    setSelectedBn(undefined)
  }, [])

  const handleStep0Change: Required<FormProps>['onChange'] = useCallback(
    (values) => {
      if (values['country-code'] !== country.code) {
        setCountryCode(values['country-code'] as string)
        setSelectedBn(undefined)
      }

      if (values['auto-timezone']) {
        disableTimezone()
      } else {
        enableTimezone()
      }
    },
    [country, disableTimezone, enableTimezone]
  )

  const handleStep0Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      if (country.bn && !searchFailed && !selectedBn) {
        ;(document.getElementById('bn-form') as HTMLFormElement).requestSubmit()
        return
      }

      allValues.current = values
      stepOn()
    },
    [country.bn, searchFailed, selectedBn, stepOn]
  )

  const handleStep1Change: Required<FormProps>['onChange'] = useCallback(
    (values) => {
      setAreaCode(values['area-code'] as string)
    },
    []
  )

  const handleStep1Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      allValues.current = { ...allValues.current, ...values }
      startPending()

      try {
        if (
          !(await Api.get('onboarding/user_available', {
            email: allValues.current.email,
          }))
        ) {
          toast.error(
            'Sorry but this email has already been used, please use a different email'
          )
          return
        }

        await Api.post('onboarding/contacts', {
          first_name: allValues.current['first-name'],
          last_name: allValues.current['last-name'],
          email: allValues.current.email,
          referrer_id: referrer?.id,
        })

        signUpDto.current = await Api.post('onboarding/sign_up', {
          country_code: allValues.current['country-code'],
          ...(country.bn
            ? selectedBn
              ? {
                  name: selectedBn.name || allValues.current.name,
                  [country.bn.name.toLowerCase()]: selectedBn.bn,
                  abn_status: selectedBn.abnStatus,
                  state: selectedBn.state,
                  postcode: selectedBn.postcode,
                }
              : {
                  name: allValues.current.name,
                  [country.bn.name.toLowerCase()]: allValues.current.bn,
                }
            : {
                name: allValues.current.name,
              }),
          studio_type: allValues.current.type,
          timezone: allValues.current['auto-timezone']
            ? moment.tz.guess()
            : allValues.current.timezone,
          first_name: allValues.current['first-name'],
          last_name: allValues.current['last-name'],
          email: allValues.current.email,
          mobile: formatMobile(
            allValues.current['area-code'] as string,
            allValues.current.phone as string
          ),
          password: allValues.current.password,
          referrer_id: referrer?.id,
          starter_guide: '',
        })

        stepOn()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [country.bn, stepOn, referrer?.id, selectedBn, startPending, stopPending]
  )

  const handleResendClick = useCallback(async () => {
    await Api.post('onboarding/reverify', { gym_id: signUpDto.current?.gym_id })
    toast.success('Email is sent')
  }, [])

  return (
    <>
      <Page background={step >= 2 ? 'background-2.jpg' : 'background-1.jpg'}>
        <div data-step={step} className={s.root}>
          {step <= 1 ? (
            <div className={s.form}>
              <section className={s.step}>
                {step === 0 && (
                  <>
                    <header className={s.header}>
                      <div className={s.logo}>
                        <Logo width="6rem" />
                      </div>
                      <div>Sign up for your XODA account</div>
                    </header>
                    <Form
                      validation={{
                        bn: [
                          {
                            test: 'valueMissing',
                            message: `${country.bn?.name} required`,
                          },
                        ],
                        name: [
                          {
                            test: 'valueMissing',
                            message: 'Business name required',
                          },
                        ],
                      }}
                      onChange={handleStep0Change}
                      onSubmit={handleStep0Submit}
                    >
                      <Field
                        name="country-code"
                        label="Country"
                        control={
                          <Select
                            options={Countries.raw.map((item) => ({
                              label: item.name,
                              value: item.code,
                            }))}
                            value={country.code}
                          />
                        }
                      />
                      {country.bn ? (
                        selectedBn ? (
                          <>
                            <Field
                              label={country.bn.name}
                              control={<>{selectedBn.bn}</>}
                            />
                            <Field
                              name="name"
                              label="Business name"
                              control={
                                selectedBn.name ? (
                                  <>{selectedBn.name}</>
                                ) : (
                                  <Input autoFocus />
                                )
                              }
                            />
                            <div className={s['back-search']}>
                              <Button
                                variant="link"
                                onClick={handleReSearchClick}
                              >
                                Back to search
                              </Button>
                            </div>
                          </>
                        ) : searchFailed ? (
                          <>
                            <Field
                              name="bn"
                              label={`${country.bn.name}`}
                              control={<Input autoFocus />}
                            />
                            <Field
                              name="name"
                              label="Business name"
                              control={<Input />}
                            />
                          </>
                        ) : (
                          <Field
                            name="bn-keyword"
                            label={`${country.bn.name} / Business name search`}
                            control={
                              <Input
                                form="bn-form"
                                defaultValue={bnKeyword}
                                placeholder={`Enter ${country.bn.name} or business name`}
                                suffixIcon={<Magnifier1 />}
                                onClickSuffix="submit"
                                autoFocus
                              />
                            }
                          />
                        )
                      ) : (
                        <>
                          <Field
                            name="name"
                            label="Business name"
                            control={<Input />}
                          />
                        </>
                      )}
                      <Field
                        name="type"
                        label="Gym/Studio type"
                        control={
                          <Select
                            options={[
                              { value: 'Bootcamp (Outdoor)' },
                              { value: 'Bootcamp (Studio)' },
                              { value: 'Boxing' },
                              { value: 'CrossFit' },
                              { value: 'Health Club' },
                              { value: 'Martial Arts' },
                              { value: 'Personal Training' },
                              { value: 'Pilates' },
                              { value: 'Spin' },
                              { value: 'Strength and Conditioning' },
                              { value: 'Yoga' },
                              { value: 'Weightlifting' },
                              { value: 'Other' },
                            ]}
                          />
                        }
                      />
                      <Field
                        name="timezone"
                        label="Timezone"
                        control={
                          !timezoneDisabled ? (
                            <Select
                              options={[
                                ...moment.tz
                                  .names()
                                  .map((item) => ({ value: item })),
                              ]}
                              defaultValue={moment.tz.guess()}
                            />
                          ) : (
                            <></>
                          )
                        }
                        after={
                          <Checkbox
                            name="auto-timezone"
                            label="Set my timezone automatically"
                            size="s"
                          />
                        }
                      />
                      <Button type="submit" block pending={pending}>
                        Continue
                      </Button>
                      <div className={s['guide-slogan']}>
                        No credit card required
                      </div>
                      <div className={s.terms}>
                        By continuing, you agree to XODA’s{' '}
                        <a
                          href="https://xoda.com/terms-conditions/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms
                        </a>
                        , and acknowledge you have read our{' '}
                        <a
                          href="https://xoda.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          privacy policy
                        </a>
                        .
                      </div>
                    </Form>
                    <Form id="bn-form" onSubmit={handleBnSearchSubmit}></Form>
                    {!!country.bn && (
                      <Modal
                        opened={bnResultOpened}
                        onRequestClose={closeBnResult}
                      >
                        <header className={s['bn-result-header']}>
                          <h1 className={s['bn-result-title']}>
                            {country.bn.name} Search Results
                          </h1>
                          {!!bnResult?.length && (
                            <div className={s['bn-result-subtitle']}>
                              Select a matching {country.bn.name} from the
                              results below
                            </div>
                          )}
                        </header>
                        <table className={s['bn-result']}>
                          <thead>
                            <tr>
                              <th scope="col">{country.bn.name}</th>
                              <th scope="col">Name</th>
                              <th scope="col">Location</th>
                            </tr>
                          </thead>
                          {bnResult && (
                            <tbody>
                              {bnResult.map((item) => (
                                <tr
                                  key={item.bn + item.name}
                                  onClick={createHandleClickBn(item)}
                                >
                                  <td>{item.bn}</td>
                                  <td>{item.name}</td>
                                  <td>{item.location}</td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                        {!bnResult ? (
                          <Loading />
                        ) : (
                          !bnResult.length && (
                            <div
                              className={s['bn-empty']}
                              style={{
                                // Cannot use images in `public` directory as static assets in CSS files.
                                backgroundImage: 'url(/images/warning-1.png)',
                              }}
                            >
                              Sorry no results found,
                              <br />
                              <Button variant="link" onClick={closeBnResult}>
                                go back
                              </Button>{' '}
                              to search again.
                            </div>
                          )
                        )}
                      </Modal>
                    )}
                  </>
                )}
                {step === 1 && (
                  <>
                    <header className={s.header}>
                      <div className={s.logo}>
                        <Logo width="6rem" />
                      </div>
                      <div>Complete your personal details</div>
                    </header>
                    <Form
                      validation={step0Validation}
                      onChange={handleStep1Change}
                      onSubmit={handleStep1Submit}
                    >
                      <FormRow>
                        <Field
                          name="first-name"
                          label="First name"
                          control={<Input autoFocus />}
                        />
                        <Field
                          name="last-name"
                          label="Last name"
                          control={<Input />}
                        />
                      </FormRow>
                      <Field
                        name="email"
                        label="Email address"
                        control={<Input type="email" />}
                      />
                      <Field
                        name="phone"
                        label="Phone"
                        control={
                          <Input
                            type="tel"
                            required={false}
                            prefix={
                              <>
                                <Select
                                  name="area-code"
                                  options={Countries.raw.map((item) => ({
                                    label: item.flag,
                                    value: item.areaCode,
                                  }))}
                                  defaultValue={country.areaCode}
                                  emoji
                                />
                                <span className={s['country-code']}>{`+${
                                  areaCode || country.areaCode
                                }`}</span>
                              </>
                            }
                          />
                        }
                      />
                      <Field
                        name="password"
                        label="Password"
                        control={<PasswordInput autoComplete="new-password" />}
                        tip="Use 8 or more characters with a mix of letters, numbers & symbols"
                      />
                      <Field
                        name="password-again"
                        label="Confirm password"
                        control={<PasswordInput />}
                      />
                      <Button type="submit" block pending={pending}>
                        Create Account
                      </Button>
                      <div className={s['back-wrap']}>
                        <Button
                          variant="text"
                          className={s.back}
                          onClick={stepOff}
                        >
                          Back
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
              </section>
            </div>
          ) : (
            <Notice
              footer={
                <>
                  Not getting the email?
                  <br />
                  Check your spam/junk folder.
                </>
              }
              shadow
            >
              <div className={s.welcome}>
                <div>
                  Welcome to XODA! We sent an email to{' '}
                  {String(allValues.current?.email)}
                </div>
                <div>Check your inbox to activate your account.</div>
              </div>
              <div>
                <div className={s['email-tip']}>Didn’t get your email?</div>
                <Button variant="ghost" onClick={handleResendClick}>
                  Resend email
                </Button>
              </div>
            </Notice>
          )}
        </div>
      </Page>
    </>
  )
}

const referrerCode = new URL(window.location.href).searchParams.get('referrer')

const step0Validation: FormProps['validation'] = {
  'first-name': [
    {
      test: 'valueMissing',
      message: 'First name required',
    },
  ],
  'last-name': [
    {
      test: 'valueMissing',
      message: 'Last name required',
    },
  ],
  email: [
    {
      test: 'valueMissing',
      message: 'Email address required',
    },
    {
      test: 'typeMismatch',
      message: 'Invalid email address',
    },
  ],
  password: [
    {
      test: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      message:
        'Use 8 or more characters with a mix of letters, numbers & symbols',
    },
  ],
  'password-again': [
    {
      test: 'valueMissing',
      message: 'Confirm password required',
    },
    {
      test: (value, values) => value === values.password,
      message: "Passwords don't match, try again.",
    },
  ],
}

export { Home }
