import { FC, PropsWithChildren } from 'react'
import { createBooleanDataset } from '../../helpers'
import s from './Text.module.css'

const Text: FC<TextProps> = (props) => {
  const { type = 'text-small', bold = false, tag, ...passingProps } = props

  const Tag = tag || (type === 'title' ? 'h1' : 'div')

  return (
    <Tag
      data-type={type}
      {...createBooleanDataset({ bold })}
      className={s.root}
      {...passingProps}
    />
  )
}

type TextProps = PropsWithChildren & {
  type?: 'title' | 'text-small' | 'text-x-small'
  bold?: boolean
  tag?: keyof HTMLElementTagNameMap
}

export { Text }
export type { TextProps }
