import { FC, PropsWithChildren } from 'react'
import s from './FormRow.module.css'

const FormRow: FC<FormRowProps> = (props) => {
  return <div {...props} className={s.root} />
}

type FormRowProps = PropsWithChildren & {}

export { FormRow }
export type { FormRowProps }
