import { FC, OptionHTMLAttributes, SelectHTMLAttributes } from 'react'
import { createBooleanDataset } from '../../../helpers'
import { ReactComponent as ArrowDown1 } from '../../../icons/arrow-down-1.svg'
import { ControlWrapper } from '../ControlWrapper'
import s from './Select.module.css'

const Select: FC<SelectProps> = (props) => {
  const { options, emoji = false, ...passingProps } = props

  return (
    <ControlWrapper>
      <select
        {...createBooleanDataset({ emoji })}
        className={s.control}
        {...passingProps}
      >
        {options.map((option) => (
          <option key={String(option.value)} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </select>
      <span className={s.icon}>
        <ArrowDown1 />
      </span>
    </ControlWrapper>
  )
}

type SelectProps = Pick<
  SelectHTMLAttributes<HTMLSelectElement>,
  'name' | 'defaultValue' | 'value' | 'required'
> & {
  options: Pick<OptionHTMLAttributes<HTMLOptionElement>, 'label' | 'value'>[]
  emoji?: boolean
}

export { Select }
export type { SelectProps }
