import { FC, PropsWithChildren } from 'react'
import s from './Page.module.css'

const Page: FC<PageProps> = (props) => {
  const { background, ...passingProps } = props

  return (
    <div
      className={s.root}
      style={{ backgroundImage: `url(/images/${background})` }}
      {...passingProps}
    />
  )
}

type PageProps = PropsWithChildren & {
  background: string
}

export { Page }
export type { PageProps }
