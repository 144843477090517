import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import { createBooleanDataset } from '../../helpers'
import { Loading } from '../Loading'
import s from './Button.module.css'

const Button: FC<ButtonProps> = (props) => {
  const {
    variant = 'default',
    block,
    pending,
    disabled,
    className,
    children,
    ...passingProps
  } = props

  const finalDisabled = disabled || pending

  return (
    <button
      type="button"
      disabled={finalDisabled}
      className={clsx(s.root, className)}
      data-variant={variant}
      {...createBooleanDataset({ block })}
      {...passingProps}
    >
      {pending && <Loading icon />}
      {children}
    </button>
  )
}

type ButtonProps = PropsWithChildren &
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    | 'type'
    | 'disabled'
    | 'form'
    | 'tabIndex'
    | 'className'
    | 'onClick'
    | 'children'
  > & {
    variant?: 'default' | 'text' | 'ghost' | 'link'
    block?: boolean
    pending?: boolean
  }

export { Button }
export type { ButtonProps }
