import { forwardRef, PropsWithChildren } from 'react'
import { ReactComponent as X1 } from '../../icons/x-1.svg'
import { Button } from '../Button'
import s from './Modal.module.css'

const Modal = forwardRef<ModalRef, ModalProps>((props, ref) => {
  const { opened, onRequestClose, children } = props

  if (!opened) {
    return null
  }

  return (
    <div className={s.root}>
      <div className={s.backdrop} onClick={onRequestClose} />
      <section className={s.main}>
        {children}
        <div className={s.close}>
          <Button variant="text" onClick={onRequestClose}>
            <X1 />
          </Button>
        </div>
      </section>
    </div>
  )
})

type ModalProps = PropsWithChildren & {
  opened: boolean
  onRequestClose?: VoidFunction
}

type ModalRef = {
  toTop: VoidFunction
}

export { Modal }
export type { ModalProps, ModalRef }
