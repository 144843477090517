import { Buffer } from 'buffer'
import { ElementCompact, xml2js } from 'xml-js'

/**
 * For some package inside `xml-js`.
 * @todo Find a more elegant way.
 */
window.Buffer = Buffer

abstract class Bn {
  static search: (keyword: string) => Promise<BnItem[]>
  protected static map: (dto: any) => BnItem[]
}

class Abn extends Bn {
  static async search(keyword: string) {
    const isName = /[^\d\s]/.test(keyword)

    const response = await fetch(
      isName
        ? `${this.ORIGIN}/ABRSearchByNameSimpleProtocol?authenticationGuid=${process.env.REACT_APP_ABR_AUTHENTICATION_GUID}&postcode=&legalName=&tradingName=&NSW=&SA=&ACT=&VIC=&WA=&NT=&QLD=&TAS=&name=${keyword}`
        : `${this.ORIGIN}/SearchByABNv202001?authenticationGuid=${process.env.REACT_APP_ABR_AUTHENTICATION_GUID}&includeHistoricalDetails=N&searchString=${keyword}`
    )

    if (!response.ok) {
      return []
    }

    const body = await response.text()
    const responseData = (xml2js(body, { compact: true }) as ElementCompact)
      .ABRPayloadSearchResults.response

    if (responseData.exception) {
      return []
    }

    return this.map(
      responseData.businessEntity202001 ||
        responseData.searchResultsList.searchResultsRecord
    )
  }

  protected static map(dto: any): BnItem[] {
    return (Array.isArray(dto) ? dto : [dto]).map((item) => ({
      bn: item.ABN.identifierValue._text,
      name: (
        item.mainName ||
        item.mainTradingName ||
        item.businessName ||
        item.otherTradingName ||
        item.dgrFundName
      )?.organisationName?._text,
      location: `${item.mainBusinessPhysicalAddress.postcode._text} ${item.mainBusinessPhysicalAddress.stateCode._text}`,
      abnStatus:
        item.ABN.identifierStatus?._text ||
        item.entityStatus.entityStatusCode._text,
      state: item.mainBusinessPhysicalAddress.stateCode._text,
      postcode: item.mainBusinessPhysicalAddress.postcode._text,
    }))
  }

  private static ORIGIN =
    'https://abr.business.gov.au/abrxmlsearch/AbrXmlSearch.asmx'
}

class Nzbn extends Bn {
  static async search(keyword: string) {
    const response = await fetch(
      `${process.env.REACT_APP_NZBN_BASE_URL}/entities?search-term=${keyword}&page-size=999`,
      {
        headers: {
          accept: 'application/json',
          'Ocp-Apim-Subscription-Key':
            process.env.REACT_APP_NZBN_SUBSCRIPTION_KEY!,
        },
      }
    )
    return this.map(await response.json())
  }

  protected static map(dto: NzbnDto): BnItem[] {
    return dto.items.map((item) => ({
      bn: item.nzbn,
      name: item.entityName,
      location: '',
    }))
  }
}

type NzbnDto = {
  pageSize: number
  page: number
  totalItems: number
  sortBy: null
  sortOrder: null
  items: {
    entityStatusCode: string
    entityName: string
    nzbn: string
    entityTypeCode: string
    entityTypeDescription: string
    entityStatusDescription: string
    tradingNames: never[]
    classifications: never[]
    previousEntityNames: string[]
    registrationDate: string
    sourceRegisterUniqueId: string
    links: {
      rel: string
      href: string
      methods: string[]
    }[]
  }[]
  links: never[]
}

type BnItem = {
  bn: string
  name: string
  location: string
  abnStatus?: string
  state?: string
  postcode?: string
}

export { Abn, Nzbn }
export type { BnItem }
