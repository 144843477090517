import {
  FC,
  FormEventHandler,
  FormHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import { Errors, ErrorsContext, validate, Validation } from '../validation'
import s from './Form.module.css'

const Form: FC<FormProps> = (props) => {
  const { validation, onChange, onSubmit, ...passingProps } = props

  const [errors, setErrors] = useState<Errors>()

  const submitted = useRef(false)

  const handleChange: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      const form = e.currentTarget

      if (submitted.current) {
        setTimeout(() => {
          setErrors(validate(form, validation))
        })
      }

      onChange?.(getValues(form))
    },
    [onChange, validation]
  )

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()

      const form = e.currentTarget
      submitted.current = true

      const newErrors = validate(form, validation)

      if (newErrors) {
        setErrors(newErrors)
      } else {
        const values = getValues(form)
        console.log(values)
        onSubmit(values)
      }
    },
    [onSubmit, validation]
  )

  return (
    <ErrorsContext.Provider value={errors}>
      <form
        noValidate
        className={s.root}
        onChange={handleChange}
        onSubmit={handleSubmit}
        {...passingProps}
      />
    </ErrorsContext.Provider>
  )
}

const getValues = (form: HTMLFormElement) =>
  Object.fromEntries(
    [...new FormData(form)].map(([name, value]) => [
      name,
      typeof value === 'string' ? value.trim() : value,
    ])
  )

type FormProps = Pick<FormHTMLAttributes<HTMLFormElement>, 'id'> &
  PropsWithChildren & {
    validation?: Validation
    onChange?: (values: Values) => void
    onSubmit: (values: Values) => void
  }

type Values = Record<string, FormDataEntryValue>

export { Form, getValues }
export type { FormProps, Values }
