import { cloneElement, FC, ReactElement, ReactNode, useContext } from 'react'
import { createBooleanDataset } from '../../../helpers'
import { ErrorsContext } from '../validation'
import s from './Field.module.css'

const Field: FC<FieldProps> = (props) => {
  const { name, label, control, tip, after } = props

  const errors = useContext(ErrorsContext)

  const error = name && errors?.[name]

  return (
    <div {...createBooleanDataset({ error })} className={s.root}>
      {label && <div className={s.label}>{label}</div>}
      {control && cloneElement(control, { name })}
      {after && <div className={s.after}>{after}</div>}
      {error ? (
        <div className={s.error}>{error}</div>
      ) : (
        tip && <div className={s.tip}>{tip}</div>
      )}
    </div>
  )
}

type FieldProps = {
  name?: string
  label?: string
  control: ReactElement
  tip?: ReactNode
  after?: ReactNode
}

export { Field }
export type { FieldProps }
