import { FC, InputHTMLAttributes, OptionHTMLAttributes } from 'react'
import s from './Checkbox.module.css'

const Checkbox: FC<CheckboxProps> = (props) => {
  const { label, size = 'm', ...passingProps } = props

  return (
    <label data-size={size} className={s.root}>
      <input type="checkbox" className={s.control} {...passingProps} />
      <span className={s.fake} />
      {label}
    </label>
  )
}

type CheckboxProps = Pick<InputHTMLAttributes<HTMLInputElement>, 'name'> &
  Pick<OptionHTMLAttributes<HTMLOptionElement>, 'label'> & {
    size?: 's' | 'm'
  }

export { Checkbox }
export type { CheckboxProps }
