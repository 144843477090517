import { FC, PropsWithChildren } from 'react'
import s from './ControlWrapper.module.css'

const ControlWrapper: FC<ControlWrapperProps> = (props) => {
  const { ...passingProps } = props

  return <div {...passingProps} className={s.root} />
}

type ControlWrapperProps = PropsWithChildren & {}

export { ControlWrapper }
export type { ControlWrapperProps }
