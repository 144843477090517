import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { Api } from '../../api'
import { Notice } from '../../components/Notice'
import s from './Verification.module.css'

const Verification: FC = () => {
  const [done, setDone] = useState(false)

  useEffect(() => {
    const token = new URL(window.location.href).searchParams.get('token')

    if (!token) {
      throw new Error('Token is not provided.')
    }

    ;(async () => {
      try {
        await Api.get('auth/verifyAccount', {}, { token })
        setDone(true)
        setTimeout(() => {
          window.location.replace(process.env.REACT_APP_WEB_PORTAL_ORIGIN!)
        }, 5000)
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      }
    })()
  }, [])

  return (
    <>
      <Helmet>
        <title>Verify Your Email</title>
      </Helmet>
      <div className={s.root}>
        {done && (
          <Notice
            title="Account activated"
            footer={
              <>
                You will be now be re-directed
                <br />
                to your new XODA account
              </>
            }
          >
            <div>Thanks for verifying your email.</div>
          </Notice>
        )}
      </div>
    </>
  )
}

export { Verification }
