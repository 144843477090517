import { FC, useCallback, useState } from 'react'
import { ReactComponent as Eye1 } from '../../../icons/eye-1.svg'
import { Input, InputProps } from '../Input'

const PasswordInput: FC<PasswordInputProps> = (props) => {
  const { ...passingProps } = props

  const [exposed, setExposed] = useState(false)

  const handleOnClickSuffix = useCallback(() => {
    setExposed((prev) => !prev)
  }, [])

  return (
    <Input
      type={exposed ? 'text' : 'password'}
      suffixIcon={<Eye1 />}
      onClickSuffix={handleOnClickSuffix}
      {...passingProps}
    />
  )
}

type PasswordInputProps = Pick<
  InputProps,
  'name' | 'pattern' | 'placeholder' | 'autoFocus' | 'autoComplete'
> & {}

export { PasswordInput }
export type { PasswordInputProps }
