import { useCallback, useState } from 'react'

/**
 * @todo Apply this to previous situations with steps.
 */
const useSteps = (length: number, start = 0) => {
  const [current, setCurrent] = useState(start)

  const max = length - 1

  const go = useCallback(
    (offset = 1) => {
      const next = current + offset

      if (next > max || next < 0) {
        return
      }

      setCurrent(next)
    },
    [current, max]
  )

  const stepOn = useCallback(() => {
    go()
  }, [go])

  const stepOff = useCallback(() => {
    go(-1)
  }, [go])

  return { step: current, stepOn, stepOff }
}

export { useSteps }
