import { FC } from 'react'
import { createBooleanDataset } from '../../helpers'
import s from './Logo.module.css'

const Logo: FC<LogoProps> = (props) => {
  const { width, center = true } = props

  return (
    <img
      src="/images/logo-1.svg"
      alt="Xoda"
      {...createBooleanDataset({ center })}
      className={s.root}
      style={{ width }}
    />
  )
}

type LogoProps = {
  width?: string
  center?: boolean
}

export { Logo }
export type { LogoProps }
