import { dedup } from '../helpers'

class Api {
  static async get<T extends keyof Dto.Get>(
    uri: T,
    params: Data,
    config?: Config
  ) {
    const response = await fetch(this.#getUrl(uri, params), {
      headers: {
        ...this.#getHeaders(),
        ...(config?.token && { authorization: `Bearer ${config?.token}` }),
      },
    })
    return await this.#getBody<Dto.Get[T]>(response)
  }

  static async post<T extends keyof Dto.Post>(
    uri: T,
    body: Data,
    config?: Config
  ) {
    const response = await fetch(this.#getUrl(uri), {
      method: 'post',
      headers: {
        ...this.#getHeaders(),
        'content-type': 'application/json',
        ...(config?.token && { authorization: `Bearer ${config?.token}` }),
      },
      body: JSON.stringify(body),
    })
    return await this.#getBody<Dto.Post[T]>(response)
  }

  static #getHeaders() {
    return {
      utc_offset: String(new Date().getTimezoneOffset()),
    }
  }

  static #getUrl(uri: string, params?: Data) {
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/${uri}`)

    if (!params) {
      return url
    }

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        dedup(value).forEach((item) => {
          url.searchParams.append(key, item)
        })
        return
      }

      url.searchParams.append(key, String(value))
    })
    return url
  }

  static async #getBody<T>(response: Response) {
    const body = await response.json()

    if (!body) {
      return
    }

    if (body.error) {
      throw new ApiError(
        { status: response.status },
        body.message || body.error
      )
    }

    if (body.errors) {
      const error = body.errors[0]
      throw new ApiError(
        { status: response.status },
        `"${error.msg}" caused by \`${error.param}\``
      )
    }

    if (!response.ok) {
      throw new ApiError({ status: response.status }, body.message)
    }

    return body as T
  }
}

class ApiError extends Error {
  status: Response['status']

  constructor(
    info: {
      status: ApiError['status']
    },
    ...passingArgs: Parameters<typeof Error>
  ) {
    super(...passingArgs)
    this.status = info.status
  }
}

type Data = Record<string, unknown>

type Config = {
  token?: string | null
}

export { Api, ApiError }
