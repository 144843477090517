import { Helmet, HelmetProvider } from 'react-helmet-async'
import { toast, Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { Api } from './api'
import './App.css'
import { Home } from './pages/Home'
import { ResetPassword } from './pages/ResetPassword'
import { Verification } from './pages/Verification'

function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s - Xoda"></Helmet>
      <SWRConfig
        value={{
          fetcher: (uri, data) => Api.get(uri, data),
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          revalidateIfStale: false,
          shouldRetryOnError: false,
          onError: (error) => {
            if (error instanceof Error) {
              /**
               * @todo Don't know why it can't show up immediately.
               */
              setTimeout(() => {
                toast.error(error.message)
              }, 100)
            }
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
        <Toaster containerClassName="toast-container" />
      </SWRConfig>
    </HelmetProvider>
  )
}

export { App }
